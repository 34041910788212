import React from "react"
import Layout from "../components/layout"

import year_2018 from '../images/2018.png'
import year_2019 from '../images/2019.png'
import year_2020 from '../images/2020.png'
import year_2021 from '../images/2021.png'
import year_2022 from '../images/2022.png'
import year_2023 from '../images/2023.png'

const yearImages = {
  '2018': year_2018,
  '2019': year_2019,
  '2020': year_2020,
  '2021': year_2021,
  '2022': year_2022,
  '2023': year_2023,
};

const ContentTemplate = ({ pageContext: { data, slug } }) => {

  const itemsByYear = data.reduce((acc, item) => {
    const year = item.node.year
    if (!acc[year]) {
      acc[year] = [];
    }
    acc[year].push(item);
    return acc;
  }, {});

  // Sort years in descending order
  const sortedYears = Object.keys(itemsByYear).sort((a, b) => a === 'null' ? 1 : b === 'null' ? -1 : b - a);

  return (
    <Layout title={slug}>
      {
        sortedYears.map(year => (
          <div key={year} style={{ marginLeft: '40px'}}>
            {year !== 'null' && <img src={yearImages[year]} alt={year} style={{ width: '15%', maxWidth: '165px' }}/>}
            {
              itemsByYear[year].map((item, index) => (
                <div key={index}>
                  <a href={item.node.pdf.publicURL} target="_blank" rel="noopener noreferrer">{item.node.name}</a><br></br>
                </div>
              ))
            }
          </div>
        ))
      }
    </Layout>
  )
}

export default ContentTemplate